import React from 'react'
import { useState, useEffect } from 'react';
import BarChart from './BarChart';

function HistoricalPerformance(props) {

    const [userData, setUserData] = useState({
        labels: props.Data.map((data) => data.Date),
        datasets: [
          {
            label: 'Price',
            data: props.Data.map((data) => data.Price),
            backgroundColor: 'rgba(54, 162, 235, 0.7)',
            type: 'bar',
          },
          {
            label: 'Price Marker',
            data: props.Data.map((data) => data.Price),
            type: 'line',
            borderColor: 'Black',
            borderWidth: 2,
          },
        ],
    });

    useEffect(() => {
      setUserData({
          labels: props.Data.map((data) => data.Date),
          datasets: [
            {
              label: 'Price',
              data: props.Data.map((data) => data.Price),
              backgroundColor: 'rgba(54, 162, 235, 0.7)',
              type: 'bar',
            },
            {
              label: 'Price Marker',
              data: props.Data.map((data) => data.Price),
              type: 'line',
              borderColor: 'Black',
              borderWidth: 2,
            },
          ],
      });
  }, [props.Data]);

return (
    <div className="container my-4">
          <div className="row">
              <div className="col-md-12 border mb-3 mb-md-0">
                  <h3 className="text-center my-2">Historical Performance {props.Login === "True" ? "(6 Months)": "(1 Month)"}</h3>
                  <BarChart Data={userData}/>
              </div>
          </div>
    </div>
  )
}

export default HistoricalPerformance