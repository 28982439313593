import React from 'react'
import { useState, useEffect } from 'react';
import BarChart from './BarChart';

function Segment2(props) {

    // eslint-disable-next-line
    const [userData, setUserData] = useState({
        labels: props.Prediction.map((prediction) => prediction.Date),
        datasets: [
          {
            label: 'Price',
            data: props.Prediction.map((prediction) => prediction.Price),
            backgroundColor: 'rgba(190, 241, 114, 0.8)',
            type: 'bar',
          },
          {
            label: 'Price Marker',
            data: props.Prediction.map((prediction) => prediction.Price),
            type: 'line',
            borderColor: 'Black',
            borderWidth: 2,
          },
        ],
    });

    useEffect(() => {
      setUserData({
        labels: props.Prediction.map((prediction) => prediction.Date),
        datasets: [
          {
            label: 'Price',
            data: props.Prediction.map((prediction) => prediction.Price),
            backgroundColor: 'rgba(190, 241, 114, 0.8)',
            type: 'bar',
          },
          {
            label: 'Price Marker',
            data: props.Prediction.map((prediction) => prediction.Price),
            type: 'line',
            borderColor: 'Black',
            borderWidth: 2,
          },
          ],
      });
  }, [props.Prediction]);

  return (
    <div className="container my-4">
      <div className="row">
        <div className="col-md-6 border mb-3 mb-md-0">
          <div className="card border-0">
            <div className="card-body">
              <h3 className="card-title text-center">Related News</h3>
              <ul className="list-group list-group-flush">
                {props.News.map((news => <li className="list-group-item" key={Math.random()}>
                  {news.id}{".) "} 
                  {news.Link ? 
                    <a href={news.Link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", cursor: "pointer"}}>
                      {news.News}
                    </a> :
                    <span>{news.News}</span>
                  }
                </li>))}
              </ul> 
            </div>
          </div>
          <p style={{fontSize: "8px"}}>*All News Data is Derived from Google News we do not Gaurentee its Relevence or Authenticity.</p>
        </div>

        <div className="col-md-6 border">
          <div className="card border-0">
            <div className="card-body">
              <h3 className="card-title text-center">AI Predictions {props.Login === "True" ? "(1 Month)": "(7 Days)"} </h3>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <p className='text-center'>*May Not Be Accurate, Use At Your Own Risk</p>
                  <BarChart Data={userData}/>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  </div>
  )
}

export default Segment2