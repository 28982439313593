import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../Styles/MainHandler.css';
import axios from 'axios';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBIcon
}
from 'mdb-react-ui-kit'; 

function Login(props) {

    var { CompanySymbol } = useParams();
    if (CompanySymbol === undefined) {
      CompanySymbol = "";
    }

    const navigate = useNavigate();

    const loggedIn = async (CompanySymbol, username, password) => {
      setLogin(true);
      if (!username || username.trim() === '') {
        setLogin(false);
        alert('Email cannot be empty');
        return;
      }
      if (!password || password.trim() === '') {
        setLogin(false);
        alert('Password cannot be empty');
        return;
      }
      let response = null; // define response before the try block
      try {
        response = await axios.post('/.netlify/functions/Login', {
          email: username,
          password: password
        });
      } catch (error) {
        setLogin(false);
        response = error.response;
      }
      
      // use the value of response after the try block
      if (response) {
        const statusCode = response.status;
    
        if (statusCode === 200) {
          const UserData = response.data.user;
          navigate(`/${CompanySymbol}`, {
            state: {
              UserName: UserData.name
            }
          });
        } else {
          setLogin(false);
          alert(response.data.message);
        }        
      }
    };

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [login, setLogin] = useState(false);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    return (
      <MDBContainer fluid>
        {login ? (
        <div className="overlay">
          <div className="loader"></div>
          <p className="loading-text">Please wait... Processing the data</p>
      </div>
      ) : null}
      <MDBCard className='text-black m-5' style={{borderRadius: '25px'}}>
        <MDBCardBody className="mt-5 mb-5">
        <MDBIcon icon='arrow-left' size='lg' className='me-2' onClick={() => navigate(`/${CompanySymbol}`)} role='button'/>
          <MDBRow className="mt-5 mb-5">
            <MDBCol md='10' lg='6' className='order-2 order-lg-1 d-flex flex-column align-items-center'>
              <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Log In</p>
              <div className="d-flex flex-row align-items-center mb-4">
                <MDBIcon fas icon="envelope me-3" size='lg'/>
                <input type="email" id="email" className="form-control" value={username} onChange={handleUsernameChange} placeholder="Your Email" />
              </div>
              <div className="d-flex flex-row align-items-center mb-4">
                <MDBIcon fas icon="key me-3" size='lg'/>
                <input type="password" id="password" className="form-control" placeholder="Enter your password" value={password} onChange={handlePasswordChange}/>
              </div>
              <div className='mb-4'>
                <a href='/register' className='text-decoration-none'>New Here?</a>
              </div>
              <button type="submit" className="btn btn-primary" onClick={() => loggedIn(CompanySymbol, username, password)}>Login</button>
            </MDBCol>
            <MDBCol md='10' lg='6' className='order-1 order-lg-2 d-flex align-items-center'>
              <MDBCardImage src='https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp' fluid/>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
    );
}

export default Login;
