import React from 'react'

function Levels(props) {
    const DailyData = props.DailyData;
    const WeeklyData = props.WeeklyData;
  return (
    <div>
        <div className="container my-4">
        <div className="row">
            <div className="col-md-12 border mb-3 mb-md-0">
            <h3 className="text-center my-2">Daily Levels</h3>
                <div className="container my-4">
                <div className="row">
                    <div className="col-md-3 border mb-3 mb-md-0">
                        <ul className="list-group list-group-flush">

                            <li className="list-group-item">
                            <strong>High: </strong> {DailyData.High}
                            </li>

                            <li className="list-group-item">
                            <strong>Low: </strong> {DailyData.Low}
                            </li>

                            <li className="list-group-item">
                            <strong>Close: </strong> {DailyData.Close}
                            </li>
                            
                        </ul>
                    </div>

                    <div className="col-md-3 border mb-3 mb-md-0">

                        <ul className="list-group list-group-flush">
                            
                            <li className="list-group-item">
                            <strong>Difference: </strong> {DailyData.Difference}
                            </li>

                            <li className="list-group-item">
                            <strong>Pivot Point: </strong> {DailyData.PivotPoint}
                            </li>
                            
                        </ul>

                    </div>

                    <div className="col-md-3 border mb-3 mb-md-0">

                        <ul className="list-group list-group-flush">

                            <li className="list-group-item">
                            <strong>Resistance 1: </strong> {DailyData.Resistance1}
                            </li>

                            <li className="list-group-item">
                            <strong>Resistance 2: </strong> {DailyData.Resistance2}
                            </li>

                            <li className="list-group-item">
                            <strong>Resistance 3: </strong> {DailyData.Resistance3}
                            </li>

                        </ul>

                    </div>

                    <div className="col-md-3 border mb-3 mb-md-0">

                        <ul className="list-group list-group-flush">

                            <li className="list-group-item">
                            <strong>Support 1: </strong> {DailyData.Support1}
                            </li>

                            <li className="list-group-item">
                            <strong>Support 2: </strong> {DailyData.Support2}
                            </li>

                            <li className="list-group-item">
                            <strong>Support 3: </strong> {DailyData.Support3}
                            </li>

                        </ul>

                    </div>
                </div>
                </div>
            </div>
        </div>
        </div>

        <div className="container my-4">
            <div className="row">
                <div className="col-md-12 border mb-3 mb-md-0">
                    <h3 className="text-center my-2">Weekly Levels</h3>
                        <div className="container my-4">
                        <div className="row">
                            <div className="col-md-3 border mb-3 mb-md-0">
                                <ul className="list-group list-group-flush">

                                    <li className="list-group-item">
                                    <strong>High: </strong> {WeeklyData.High}
                                    </li>

                                    <li className="list-group-item">
                                    <strong>Low: </strong> {WeeklyData.Low}
                                    </li>

                                    <li className="list-group-item">
                                    <strong>Close: </strong> {WeeklyData.Close}
                                    </li>
                                    
                                </ul>
                            </div>

                            <div className="col-md-3 border mb-3 mb-md-0">

                                <ul className="list-group list-group-flush">
                                    
                                    <li className="list-group-item">
                                    <strong>Difference: </strong> {WeeklyData.Difference}
                                    </li>

                                    <li className="list-group-item">
                                    <strong>Pivot Point: </strong> {WeeklyData.PivotPoint}
                                    </li>
                                    
                                </ul>

                            </div>

                            <div className="col-md-3 border mb-3 mb-md-0">

                                <ul className="list-group list-group-flush">

                                    <li className="list-group-item">
                                    <strong>Resistance 1: </strong> {WeeklyData.Resistance1}
                                    </li>

                                    <li className="list-group-item">
                                    <strong>Resistance 2: </strong> {WeeklyData.Resistance2}
                                    </li>

                                    <li className="list-group-item">
                                    <strong>Resistance 3: </strong> {WeeklyData.Resistance3}
                                    </li>

                                </ul>

                            </div>

                            <div className="col-md-3 border mb-3 mb-md-0">

                                <ul className="list-group list-group-flush">

                                    <li className="list-group-item">
                                    <strong>Support 1: </strong> {WeeklyData.Support1}
                                    </li>

                                    <li className="list-group-item">
                                    <strong>Support 2: </strong> {WeeklyData.Support2}
                                    </li>

                                    <li className="list-group-item">
                                    <strong>Support 3: </strong> {WeeklyData.Support3}
                                    </li>

                                </ul>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default Levels