import React, { useState, useEffect } from 'react';
import axios from 'axios';

function TopNavbar(props){

    function handleSearchLogin(company){
        setSearchQuery(company);
        setUrl("/" + company + "/login");
        setRegisterurl("/" + company + "/Register");
        props.onSearch(company);
    }

    // Check if the user is logged in
    const isLoggedIn = props.Login;

    // Set up state for the search query and login URL
    const [searchQuery, setSearchQuery] = useState('');
    const [searchFocus, setsearchFocus] = useState(false);
    const [url, setUrl] = useState("/login");
    const [Registerurl, setRegisterurl] = useState("/Register");

    // Handle changes to the search input
    const handleInputChange = (e) => {
        setSearchQuery(e.target.value); // update the search query state
    };

    // Handle submission of the search form
    const handleSubmit = (e) => {
        e.preventDefault();
        setUrl("/" + searchQuery + "/login"); // update the login URL based on the search query
        setRegisterurl("/" + searchQuery + "/Register");
        props.onSearch(searchQuery); // call the onSearch prop with the search query as an argument
    };

    const [suggestions, setSuggestions] = useState([]);
    useEffect(() => {
        const getSuggestions = async (value) => {
          const response = await axios.post('/.netlify/functions/Recommendation', { company: value });
          setSuggestions(Object.entries(response.data));
        };
      
        getSuggestions(searchQuery);
      }, [searchQuery]);
      

      useEffect(() => {
        const searchInput = document.querySelector('#search');
        
        const handleFocus = () => {
          setsearchFocus(true);
        };
        
        const handleBlur = () => {
            setTimeout(() => {
                setsearchFocus(false);
              }, 200);
        };
        
        searchInput.addEventListener('focus', handleFocus);
        searchInput.addEventListener('blur', handleBlur);
        
        return () => {
          searchInput.removeEventListener('focus', handleFocus);
          searchInput.removeEventListener('blur', handleBlur);
        };
      }, [])

    return(
        // The navbar HTML and Bootstrap classes
        <nav className="navbar bg-primary navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
                {/* The navbar toggle button */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                {/* The navbar logo */}
                <a className="navbar-brand me-4 text-light" href="/">Stock Sense</a>
                
                {/* The navbar search and user links */}
                <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                    {/* The search form */}
                    <form className="d-flex my-2 my-lg-0" role="search" onSubmit={handleSubmit}>
                        <div className="col-lg-12 me-2">
                            {/* The search input */}
                            <input autoComplete='off' id='search' className="form-control me-2" type="search" placeholder="Company Symbol" aria-label="Search" value={searchQuery} onChange={handleInputChange} ></input>
                        </div>
                        {/* The search button */}
                        <button className="btn btn-outline-light" type="submit">Search</button>
                    </form>

                    {/* The user links */}
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        {/* If the user is logged in, show their name and a logout link */}
                        <li className="nav-item">
                            {isLoggedIn === "True" ? (
                                <div className="d-flex align-items-center">
                                    <span className="text-light me-2">Welcome Back, <strong>{props.UserName}</strong></span>
                                    <span className="nav-link active text-light" onClick={props.onLogOut} style={{cursor:"pointer"}}>Log out</span>
                                </div>
                            ) : (
                                // If the user is not logged in, show a login link
                                <a className="nav-link active text-light" aria-current="page" href={url} ><span>Login</span></a>
                            )}
                        </li>
                        {/* The register link */}
                        <li className="nav-item">
                            <a className="nav-link active text-light" href={Registerurl}>Register</a>
                        </li>
                    </ul>
                </div>

                {(searchFocus && searchQuery.length >= 3) && (
                <div id='recommendation' className="container my-5 d-none d-lg-block" style={{position: "absolute", top: "10%", zIndex: "99999"}}>
                    <div className="row">
                        <div className="col-md-12 mb-3 mb-md-0">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-5 mb-3 mb-md-0">
                                        <ul className="list-group list-group-flush" style={{ paddingLeft: "5rem", borderRadius: "0.5rem" }}>
                                            {suggestions.map((suggestion) => (<li key={Math.random()} className="list-group-item border border-dark border-2" onClick={() => handleSearchLogin(suggestion[0])}>
                                                {suggestion[1]}
                                            </li>))}
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            </div>
        </nav>
    );
}

export default TopNavbar;
