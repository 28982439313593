import React from 'react'
import '../Styles/Follow_Footer.css'

function FollowUs() {
  return (
    <div className="container my-4">
            <div className="row border">
                    <div className="col-md-12 mb-3 mb-md-0">
                        <h4 className="text-center my-2">Follow us</h4>
                            <p className="text-left my-4">
                                <a href='https://www.facebook.com/'><i className="fa-brands fa-facebook fa-xl" style={{color: "#1877f2"}}></i></a>
                                <a href='https://www.instagram.com/'><i className="fa-brands fa-instagram fa-xl" style={{color: "#ef4c5e"}}></i></a>
                                <a href='https://twitter.com/'><i className="fa-brands fa-twitter fa-xl"  style={{color: "#1da1f2"}}></i></a>
                                <a href='https://www.youtube.com/'><i className="fa-brands fa-youtube fa-xl" style={{color: "#f20708"}}></i></a>
                                <a href='https://www.reddit.com/'><i className="fa-brands fa-reddit fa-xl" style={{color: "#ff4500"}}></i></a>
                                <a href='https://www.whatsapp.com/'><i className="fa-brands fa-whatsapp fa-xl" style={{color: "#48c857"}}></i></a>
                                <a href='https://discord.com/'><i className="fa-brands fa-discord fa-xl" style={{color: "#5865f2"}}></i></a>
                                <a href='https://www.twitch.tv/'><i className="fa-brands fa-twitch fa-xl" style={{color: "#8b44f7"}}></i></a>
                            </p>
                    </div>

            </div>
    </div>
  )
}

export default FollowUs