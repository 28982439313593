import GaugeChart from 'react-gauge-chart'

const chartStyle = {
    height: 190,
}

function RiskCalculator(value) {
    value /= 100;
    if (value <= 0.2) {
      return "Low Risk"
    } else if (value <= 0.4) {
      return "Medium Risk"
    } else if (value <= 0.6) {
      return "High Risk"
    } else if (value <= 0.8) {
      return "Very High Risk"
    } else {
      return "Extreme Risk"
    }
}

function Segment1(props){

    function Info(){
        return(
            <div className="card border-0">
            <div className="card-body">
              <h3 className="card-title text-center">Company Information</h3>
              <ul className="list-group list-group-flush">
                <li className="list-group-item"><strong>Company Name:</strong> {props.Cname}</li>
                <li className="list-group-item"><strong>Symbol:</strong> {props.Csymbol}</li>
                <li className="list-group-item"><strong>Industry:</strong> {props.Cindustry}</li>
                <li className="list-group-item"><strong>Sector:</strong> {props.Csector}</li>
                <li className="list-group-item"><strong>Date of IPO:</strong> {props.Cipo}</li>
              </ul>
            </div>
          </div>);
    };

    function Risk(){
        return(
            
            <div className="card border-0">
            <div className="card-body">
              <h3 className="card-title text-center">Risk Assessment</h3>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                    <GaugeChart id="gauge-chart1" style={chartStyle}
                        percent={props.risk}
                        nrOfLevels={5} 
                        textColor={"#000000"}
                        animate={true}
                        animDelay={0}
                        formatTextValue={value => RiskCalculator(value)}              
                    />
                </li>
              </ul>
            </div>
          </div>
        );
    };

    return(
        <div className="container my-4">
            <div className="row">
                <div className="col-md-6 border mb-3 mb-md-0">
                    <Info />
                </div>

                <div className="col-md-6 border">
                    <Risk />
                </div>
            </div>
        </div>
    );
}

export default Segment1;