import React from 'react'
import Notice from './Notice';
import FollowUs from './FollowUs';

function Footer() {
  return (
    <div className="container">
            <div className="row ">
                <div className="col-md-4 mb-md-0">
                        <FollowUs />
                </div>

                <div className="col-md-8 mb-md-0">
                        <Notice />
                </div>
            </div>

            <div className="row ">
                <div className="col-md-11 text-center mb-md-0"style={{fontSize: "10px"}}>
                        <p>Developed By Vishal Mittal<br></br>
                        ©2023 - 2024 <a href="mailto:vishalmittalrohini@gmail.com">Vishal Mittal</a> | All Rights Reserved</p>
                </div>
            </div>
    </div>
  )
}

export default Footer