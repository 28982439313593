import React from 'react'

function HomePage() {
  const handleButtonClick = () => {
    const searchInput = document.getElementById('search');
    if (searchInput) {
      searchInput.focus();
    }
  };
  return (
    <section className="vh-100 d-flex align-items-center justify-content-center" style={{background: "linear-gradient(to bottom, #f5f5f5, #8ab6d6)" }}>
            <div className="container">
              <div className="row my-4">
                <div className="col-md-7 text-center my-4">
                  <h1 className="display-1 font-weight-bold">Stock Sense</h1>
                  <p className="lead">A one stop destination for all your stock market needs.</p>
                  <p>What are you waiting for? Start your investing journey now.</p>
                  <button className="btn btn-lg btn-primary" onClick={handleButtonClick}>Try our service absolutely for free by analysing Your favourite company from above</button>
                </div>

                <div className="col-md-5 text-center">
                  <img src={process.env.PUBLIC_URL+"IndexImage.png"} alt="Index" className="img-fluid d-none d-md-block"/>
                </div>
              </div>
            </div>
    </section>
  )
}

export default HomePage
