import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../Styles/MainHandler.css';
import axios from 'axios';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBIcon
  }
  from 'mdb-react-ui-kit'; 

function Register() {

    var { CompanySymbol } = useParams();
    if (CompanySymbol === undefined) {
      CompanySymbol = "";
    }
    
    const navigate = useNavigate();

    const registered = async (CompanySymbol, name, email, phone, password) => {
      setRegister(true);
      if (!name || name.trim() === '') {
        setRegister(false);
        alert('Name cannot be empty');
        return;
      }
      if (!email || email.trim() === '') {
        setRegister(false);
        alert('Email cannot be empty');
        return;
      }
      if (!password || password.trim() === '') {
        setRegister(false);
        alert('Password cannot be empty');
        return;
      }
        let response = null;
        try {
          response = await axios.post('/.netlify/functions/Register', {
            name: name,
            pnumber: phone,
            email: email,
            password: password
          });
        } catch (error) {
          setRegister(false);
          response = error.response;
        }
        
        if (response) {
          const statusCode = response.status;
      
          if (statusCode === 200) {
            navigate(`/${CompanySymbol}`, {
              state: {
                UserName: name
              }
            });
          } else {
            setRegister(false);
            alert(response.data.message);
          }        
        }
      };

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [register, setRegister] = useState(false);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };


  return (
    <MDBContainer fluid>

      {register ? (
        <div className="overlay">
          <div className="loader"></div>
          <p className="loading-text">Please wait... Processing the data</p>
      </div>
      ) : null}

      <MDBCard className='text-black m-5' style={{borderRadius: '25px'}}>
        <MDBCardBody className="mt-5 mb-5">
        <MDBIcon icon='arrow-left' size='lg' className='me-2' onClick={() => navigate(`/${CompanySymbol}`)} role='button'/>
          <MDBRow className="mt-5 mb-5">
            <MDBCol md='10' lg='6' className='order-2 order-lg-1 d-flex flex-column align-items-center'>
              <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Sign up</p>
              <div className="d-flex flex-row align-items-center mb-4 ">
                <MDBIcon fas icon="user me-3" size='lg'/>
                <input type="text" id="name" className="form-control" value={name} onChange={handleNameChange} placeholder="Your Name" />
              </div>
              <div className="d-flex flex-row align-items-center mb-4">
                <MDBIcon fas icon="envelope me-3" size='lg'/>
                <input type="email" id="email" className="form-control" value={email} onChange={handleEmailChange} placeholder="Your Email" />
              </div>
              <div className="d-flex flex-row align-items-center mb-4">
                <MDBIcon fas icon="phone me-3" size='lg'/>
                <input type="tel" id="phone" className="form-control" value={phone} onChange={handlePhoneChange} placeholder="Your Phone Number" />
              </div>
              <div className="d-flex flex-row align-items-center mb-4">
                <MDBIcon fas icon="key me-3" size='lg'/>
                <input type="password" id="password" className="form-control" value={password} onChange={handlePasswordChange} placeholder="Password" />
              </div>
              <div className='mb-4'>
                <a href='/login' className='text-decoration-none'> Already A User?</a>
              </div>
              <button type="submit" className="btn btn-primary" onClick={() => registered(CompanySymbol, name, email, phone, password)}>Register</button>
            </MDBCol>
            <MDBCol md='10' lg='6' className='order-1 order-lg-2 d-flex align-items-center'>
              <MDBCardImage src='https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp' fluid/>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
</MDBContainer>


  );
}

export default Register;
