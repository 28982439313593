import React from 'react'

const styles = {
    textAlign: 'justify',
    color: '#454545',
    fontSize: '10px'
  };

function Notice() {
  return (
    <div className="container my-4">
            <div className="row border">
                    <div className="col-md-12 mb-3 mb-md-0">
                        <h6 className="text-center my-2" style={{fontSize: '13px' }}>*Important Notice</h6>
                            <p className="text-justify" style={styles}>
                                Stock Sense provides stock market information, including AI predictions, for informational purposes only. Please be aware that investing in the stock market is subject to market risk, and the accuracy of AI predictions cannot be guaranteed. We recommend that you exercise your own judgment and conduct your own research before making any investment decisions. Stock Sense does not take responsibility for any losses that may occur as a result of using our website or its contents. By using our website, you acknowledge that you understand and accept these risks.
                            </p>
                    </div>
            </div>
    </div>
  )
}

export default Notice