import { useParams, useLocation } from 'react-router-dom';
import TopNavbar from './Top_Navbar';
import Segment1 from './Company_Info_And_Risk_Segement';
import HistoricalPerformance from './Historical_Performance'
import Segment2 from './News_And_Prediction';
import Levels from './Levels';
import { useState, useEffect  } from 'react';
import Footer from './Footer';
import HomePage from './HomePage';
import '../Styles/MainHandler.css';

function MainHandler() {

  const [loggedIn, setLogin] = useState("False");

  const { state } = useLocation();
  if (state !== undefined && state !== null) {
    var { UserName } = state;
  }
  

  useEffect(() => {
    if (typeof UserName === "string" && UserName.trim().length > 0) {
      setLogin("True");
    } else {
      setLogin("False");
    }
  }, [UserName]);

  function HandleLogOut() {
    setLogin("False");
  }

  const { CompanySymbol } = useParams();

  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState([]);

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (CompanySymbol === undefined) {
      return;
    }

    setSearchValue(CompanySymbol);
  }, [CompanySymbol]);

    useEffect(() => {
      if (searchValue === '') {
        return;
      }
      async function fetchData() {
        setLoading(true);
        try {
          const response = await fetch("https://vishalmittal.pythonanywhere.com/" + searchValue + "/" + loggedIn + "?format=json");
          if (!response.ok) {
            setLoading(false);
            setSearchValue('');
            alert("Symbol data not available. Please check the symbol and try again.");
            return;
          }
          const jsonData = await response.json();
          setData(jsonData);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setSearchValue('');
          alert("Error fetching data. Please try again later.");
        }
      }

      fetchData();
    }, [searchValue, loggedIn]);
  
  return (    
    <div className="App">

      <TopNavbar onSearch={handleSearch} Login={loggedIn} UserName = {UserName} onLogOut={HandleLogOut}/> 

      {loading ? (
        <div className="overlay">
          <div className="loader"></div>
          <p className="loading-text">Please wait... Processing the data</p>
      </div>
      ) : null}

      {Data.length > 0 ? (

        <div>
          
          <Segment1
          Cname = {Data[0].Cname}
          Csymbol = {Data[0].Csymbol}
          Cindustry = {Data[0].Cindustry}
          Csector = {Data[0].Csector}
          Cipo = {Data[0].Cipo}
          risk = {Data[0].risk}
          />

          <HistoricalPerformance 
            Data={Data[1]}
            Login = {loggedIn}
          />
        
          <Segment2 
            News = {Data[2]}
            Prediction = {Data[3]}
            Login = {loggedIn}
          />
        
          <Levels
            DailyData={Data[4]}
            WeeklyData={Data[5]}
          />

          <Footer />   
          
        </div>
      ): (
        <div>
          <HomePage />

        </div>
      )}
    </div>
  
  );
}

export default MainHandler;
